<template>
  <div style="padding: 20px">
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>

    <div class="box">
      <p class="h2 head-text">Bulk Repayment Manager</p>

      <!-- <download-excel
        class="btn download-box"
        :data="data"
        :fields="json_fields"
        name="customers.xls"
      >
        Download Template
      </download-excel> -->
      <a id="excel__download" class="btn download-box" @click="processDownload">
        Download Template
      </a>
    </div>

    <hr style="border: 1.3px solid #e5e5e5" />

    <div class="box">
      <div class="dash-box">
        <label for="file-upload" class="btn-black">Choose XLSX file</label>
        <p class="tbody-child">{{ " " }}{{ file.name }}</p>
        <input id="file-upload" type="file" @change="onFileChange" />
      </div>

      <div class="hid-box">
        <button
          :disabled="file == ''"
          @click="uploadFile"
          :class="!file ? 'disable-btn' : 'btn-black'"
        >
          {{ submitting ? "Please wait..." : "Upload Template" }}
        </button>
      </div>
    </div>

    <hr style="border: 1.3px solid #e5e5e5" />

    <div class="ma-table">
      <div class="ma-thead">
        <div v-for="(text, i) in thead_texts" :key="i" class="thead-child">
          {{ text }}
        </div>
      </div>

      <hr style="border: 0.6px solid #e5e5e5" />
      <div v-if="bulkRepayments.length > 0">
        <div v-for="(text, i) in bulkRepayments" :key="i">
          <div class="ma-tbody">
            <div
              style="text-align: start; word-wrap: break-word"
              class="tbody-child"
            >
              {{ text.fileName }}
            </div>
            <div class="tbody-child">
              {{ text.numberOfUploads }}
            </div>
            <div class="tbody-child">
              {{ text.success ? text.success : "" }}
            </div>
            <div style="color: #bf0000" class="tbody-child">
              {{ text.failed ? text.failed : "" }}
            </div>
            <div :style="colorType(text.isApproved, text.isDeclined)" class="tbody-child">
              {{ getStatus(text.isApproved, text.isDeclined) }}
              <!-- {{ text.isApproved ? "Approved" : "Pending" }} -->
            </div>
            <div class="tbody-child text--capital">
              {{ text.approvedBy ? text.approvedBy : "" }}
            </div>
            <div class="tbody-child dropdown">
              <button
                class="btn dropdown-toggle action"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Action
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <router-link
                  :to="`/view-bulk-manager/${text.batchKey}`"
                  tag="a"
                  class="dropdown-item"
                >
                  View
                </router-link>
              </div>
            </div>
          </div>

          <hr style="border: 0.6px solid #e5e5e5" />
        </div>

        <div class="pagination">
          <div class="d-flex align-items-center justify-content-between w-100">
            <div class="text--capital text--regular pagination__text">
              row per page:
              <span
                class="d-inline-block text--primary"
                style="padding-right: 15px"
              >
                <select @change="changeLength" v-model="size">
                  <option :value="5">5</option>
                  <option :value="10">10</option>
                  <option :value="20">20</option>
                  <option :value="30">30</option>
                  <option :value="50">50</option>
                </select>
              </span>
              <span class="d-inline-block text--primary">{{
                !paginations.hasPrevious
                  ? paginations.currentPage
                  : size * (paginations.currentPage - 1) + 1
              }}</span>
              -
              <span class="d-inline-block text--primary">{{
                paginations.hasNext
                  ? size * paginations.currentPage
                  : paginations.totalCount
              }}</span>
              of
              <span>{{ paginations ? paginations.totalCount : 0 }}</span>
            </div>
            <div
              class="pagination__buttons d-flex align-items-center justify-content-between"
              v-if="paginations"
            >
              <button
                class="button button--sm text--regular"
                @click="changePage('previous')"
                :disabled="!paginations.hasPrevious"
              >
                <ion-icon name="chevron-back-outline"></ion-icon>
              </button>
              <button
                v-for="page in paginations.totalPages"
                :key="page"
                :class="{
                  'text--white button--primary ':
                    paginations.currentPage === page,
                }"
                class="button text--regular button--sm ml-2"
                @click="changePage(page)"
              >
                {{ page }}
              </button>
              <button
                class="button text--regular button--sm ml-2"
                @click="changePage('next')"
                :disabled="!paginations.hasNext"
              >
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="loading && bulkRepayments.length < 1">
        <div class="d-flex align-items-center justify-content-center p5">
          <div
            class="spinner-border"
            style="width: 3rem; height: 3rem"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <span class="text--black text--600 text--medium d-inline-flex ml-4"
            >Please wait</span
          >
        </div>
      </div>

      <div v-else>
        <div
          class="d-flex flex-column align-items-center justify-content-center p5"
        >
          <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

          <span class="d-block text--danger text--600 text--medium mt-3 mb-5">
            No data available</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import * as XLSX from "xlsx";
// import JsonExcel from "vue-json-excel";

import ApiService from "@/core/services/api.loan.service";
import { GET_ALL_BULK_REPAYMENT } from "./../../../../core/services/store/bulkrepayment.module";

import { Role } from "@/core/services/role.js";

export default {
  name: "BulkRepaymentManager",
  components: {
    // downloadExcel: JsonExcel,
  },
  data() {
    return {
      // json_fields: {
      //   "Customer ID": "customer_id",
      //   "Loan ID": "loan_id",
      //   "Amount Received": "amount_rec",
      //   "Payment Date": "payment_date",
      //   "Cheque Number": "cheque_num",
      //   Note: "note",
      // },
      // data: [{}],
      thead_texts: [
        "Batch Name",
        "Uploaded",
        "Successful",
        "Failed",
        "Approval Status",
        "Approved By",
        "Action",
      ],
      empty: [],
      file: "",
      excel_file: "",
      submitting: false,
      alert: {
        status: "",
        title: "",
        description: "",
        show: false,
      },
      pages: 1,
      size: 5,
      viewData: {},
    };
  },

  computed: {
    ...mapState({
      bulkRepayments: (state) => {
        return state.bulkrepayment.allBulkRepayments.data;
      },
      paginations: (state) => {
        return state.bulkrepayment.allBulkRepayments;
      },
      loading: (state) => {
        return state.bulkrepayment.loading;
      },
      user: (state) => state.auth.user,
    }),
    userCanApproveBulk() {
      const disbursement = this.user.scopes.find(
        (item) => item.scopeName === Role.CreditOperations
      );
      const { permissionsList } = disbursement;
      return permissionsList.CanBulkRepaymentUpload;
    },
  },

  methods: {
    getBulkRepaymentsData() {
      this.$store.dispatch(GET_ALL_BULK_REPAYMENT, {
        pageindex: this.pages,
        pagesize: this.size,
      });
    },

    getStatus(status, isDecline) {
      if (isDecline) return "Declined";
      if (status) return "Approved";
      return "Pending";
    },

    colorType(status, declined) {
      if (declined) return "color:#DE791B";
      if (status) return "color:#000000";
      return "color:#DE791B";
    },

    changePage(type) {
      if (type === "next") {
        this.pages++;
      } else if (type === "previous") {
        this.pages--;
      } else {
        this.pages = type;
      }

      this.getBulkRepaymentsData();
    },

    changeLength(e) {
      const { value } = e.target;
      this.size = parseInt(value);
      this.pages = 1;
      this.getBulkRepaymentsData();
    },

    onFileChange(event) {
      this.file = event.target.files ? event.target.files[0] : "";

      if (this.file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.excel_file = e.target.result;
        };

        reader.readAsBinaryString(this.file);
      }
    },

    processDownload() {
      let wb = XLSX.utils.book_new();

      wb.Props = {
        Title: "Template Download",
        Subject: "Bulk Repayment Excel Sheet",
        Author: "Prolean",
        createdDate: Date.now(),
      };

      wb.SheetNames.push("RepaymentTemplate");

      let sheetData = [
        [
          "Customer ID",
          "Loan ID",
          "Amount Received",
          "Payment Date",
          "Cheque Number",
          "Note",
        ],
      ];

      let ws = XLSX.utils.aoa_to_sheet(sheetData);

      wb.Sheets["RepaymentTemplate"] = ws;

      let wbSheet = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      this.saveExcelSheet(this.convertBinaryToOctetStream(wbSheet));
    },

    convertBinaryToOctetStream(sheet) {
      let buf = new ArrayBuffer(sheet.length);
      let view = new Uint8Array(buf);

      for (let i = 0; i < sheet.length; i++) {
        view[i] = sheet.charCodeAt(i) & 0xff;
      }

      return buf;
    },

    saveExcelSheet(data) {
      const url = URL.createObjectURL(
        new Blob([data], { type: "application/vnd.ms-excel" })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "bulkrepayment_template.xlsx");
      document.body.appendChild(link);
      link.click();
    },

    uploadFile() {
      if (this.file) {
        let formData = new FormData();
        formData.append("repaymentFile", this.file);
        const url = `LoanAccount/UploadBulkRepayment`;
        this.submitting = true;

        ApiService.postFormData(url, formData)
          .then((response) => {
            this.submitting = false;
            this.file = "";
            this.alertSuccess(response.data.message);
            this.getBulkRepaymentsData();
          })
          .catch((err) => {
            this.submitting = false;
            this.alertError(err.response.data.message);
          });
      } else {
        this.alertError("No file was added for upload");
      }
    },

    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },

    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
  },
  mounted() {
    this.getBulkRepaymentsData();
  },
};
</script>

<style scope>
.head-text {
  font-weight: 600;
  font-size: 1.8rem;
  font-family: "Montserrat-semibold";
  line-height: 32px;
  color: #1e1f20;
  text-transform: capitalize;
  margin-bottom: 5px;
}
.box {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.download-box {
  width: 185px;
  height: 40px;
  background: #e9e7e7;
  border-radius: 5px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 32px;
  color: #000000;
  text-align: center;
}
.btn-black {
  width: 185px;
  height: 40px;
  background: #000000;
  border-radius: 5px;
  border: 1px solid #000000;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 37px;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
}
.dash-box {
  width: 75%;
  border: 1px dashed #c4c4c4;
  box-sizing: border-box;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 15px;
}
.hid-box {
  width: 25%;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.thead-child {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #9596a2;
  width: 13%;
  text-align: center;
}
.tbody-child {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  color: #000000;
  width: 13%;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.ma-thead,
.ma-tbody {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}
.ma-table {
  display: flex;
  flex-direction: column;
}
.action {
  width: 95px;
  height: 35px;
  background: #e9e7e7;
  border-radius: 5px;
  border: 1px dashed #e9e7e7;
  font-weight: 500;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.action-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.action-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.disable-btn {
  background: #e9e7e7;
  width: 185px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #e9e7e7;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 37px;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
}
input[type="file"] {
  display: none;
}
</style>
